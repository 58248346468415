import React from "react";
import ThemeLayout from "../../layout/themeLayout";
import { useEffect } from "react";

const PrivacyPolicy = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const targetId = hash.substring(1);
    
          const targetElement = document.getElementById(targetId);
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        }
      }, []);
    return (
        <ThemeLayout>
            <section>
                <div className="gap">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12">
                                <h3 className="my-3">Reverie Privacy Policy</h3>
                                <div className="py-3">
                                    <h5>Data Collection</h5>
                                    <p>As part of our goal to create a secure social platform, we give you the ability to share and connect with people globally. This policy outlines the data we gather, its uses, and dissemination. </p>
                                    <p>As you read through our privacy policy, remember that it applies to all Reverie brands, services, and products that don't have their privacy policies or links to them. </p>
                                    <h5>Personal Information Policies</h5>
                                    <p>
                                        Reverie provides this privacy statement to let you know about our policies and procedures for gathering, using and disclosing personal data from the Reverie community, including users of the Reverie website and
                                        any other Reverie-provided goods or services. This privacy statement covers the only information you give us via the Services, and Reverie may occasionally update it to reflect changes to our information
                                        policies.
                                    </p>
                                    <h5>Email Notification</h5>
                                    <p>
                                        Suppose Reverie makes any significant or fundamental changes to this policy. In that case, we will give you an earlier notification through email to your registered Reverie account. We suggest you frequently
                                        check this page for the most recent details on our privacy policies.{" "}
                                    </p>
                                    <h5>What kind of data do we collect?</h5>
                                    <p>
                                        We need to process information about you to provide the Reverie products. Depending on how you use our products or services, we may gather different types of information. By accessing the Reverie settings, you
                                        may discover how to see and delete the data we gather. The term "using data" information refers to using cookies on a computer, going to subject the data to statistical or another study, and using or handling
                                        the data in any other manner, including but not limited to gathering, accumulating, reviewing, deleting, and to use, combining, modifying, failing to disclose, and sending data within our organization.
                                    </p>
                                    <h5>Information Gathering and Usage</h5>
                                    <p>Reverie's main objectives in gathering data from website users are to offer and enhance the website's features and information, manage members' use of the website, and make the website easier to browse.</p>
                                    <h5>Personal Information </h5>
                                    <p>
                                        Reverie utilizes your data to provide the website and other services. We ask for personally identifiable information from you when you join Reverie and register as a member, whether you decide to post a video
                                        or participate in a challenge or when you want to get in touch with another member. This info can be used to contact or identify you as Personal Data, but it excludes your bank account number or billing
                                        information. Personal information includes, but is not limited to, your name, phone number, personal identification numbers, email address, and home address. Your credit card number and other payment
                                        information can also be requested by Reverie. Personal information includes both identity information and billing information.
                                    </p>
                                    <h5>How Do We Collect Personal Data? </h5>
                                    <p>Following your registration to create your account when using the service, we collect Personal Information. Through the website's registration page, you can sign up to participate.</p>
                                    <h5>Third-Party Policy </h5>
                                    <p>
                                        By logging into any online accounts you might have with third-party service providers, such as Facebook or Google, you can also register to join. You can link your Reverie account with Third-Party Accounts as
                                        part of the website's functionality by either giving Reverie your Third-Party Account login details via the website. You can also be giving Reverie access to your Third Party as permitted by the valid terms of
                                        service that rule your use of each Third-Party Account.
                                    </p>
                                    <p>
                                        You agree and acknowledge that you have the right to give Reverie access to your third-party account and your login details without violating any of the rules governing your use of the third-party account in
                                        question, without charging Reverie any fees, and without subjecting Reverie to any usage restrictions imposed by the relevant TPA.
                                    </p>
                                    <p>
                                        Reverie will obtain the Personal Information you have provided to the applicable TPA, including your contact details, email address, profile photo, names of TPA friends, names of TPA groups to which you belong,
                                        and other information you make publicly available via the relevant TPA, and other information you authorize Reverie to access by authorizing the TPA to provide such information, from your Third-Party Account if
                                        you register by logging into a Third Party Account through our website.
                                    </p>
                                    <h5>Third-Party Accounts</h5>
                                    <p>
                                        You understand that by giving us access to said TPA, we will access, make available, and store the information in your Third-Party Accounts so that it is accessible on and through your Reverie account on the
                                        website. Based on the third-party accounts you select and following the privacy settings you've selected in those third-party accounts.
                                    </p>
                                    <h5>Additional Data Gathering </h5>
                                    <p>
                                        To administer and customize your Reverie Account profile, Reverie will additionally collect the additional information you give at registration, not personal information. We primarily use your Personal
                                        Information to deliver the service, execute transactions, and manage requests.
                                    </p>
                                    <h5 id="payment-data">Payment Card Data</h5>
                                    <p>We value your security and privacy, which is why we use a trusted third-party payment provider. Here's why you can trust us:</p>
                                    <ul>
                                        <li className="my-2" style={{
                                            color: "#5a6381"
                                        }}><span className="font-weight-bold">Your Information is Secure:</span> We don't store your payment details on our servers. This means your sensitive information is never at risk with us.</li>
                                        <li className="my-2" style={{
                                            color: "#5a6381"
                                        }}><span className="font-weight-bold">Encrypted Transactions:</span> Our payment process is fully encrypted, ensuring your data remains confidential during transmission.</li>
                                        <li className="my-2" style={{
                                            color: "#5a6381"
                                        }}><span className="font-weight-bold">Industry-Standard Security:</span> We follow the highest industry standards to protect your information, so you can have peace of mind.</li>
                                    </ul>
                                    
                                    <p>Your trust is important to us, and we're committed to providing a secure and worry-free payment experience.</p>
                                    <h5>Other Personal Data</h5>
                                    <p>
                                        Suppose some Non-Identifying Data is linked with other identifiers like your area code and street location in a way that makes it possible to identify you. In that case, the combined information would be
                                        regarded as a part of your Personal Information. In that case, it will be regarded as a component of your Personal Information. But when used alone or in combination with only other non-identifying information,
                                        the same information is categorized as non-information demographic information.
                                    </p>
                                    <h5>How Can Reverie connect to you?</h5>
                                    <p>
                                        As a member of the Reverie community, Reverie may also contact you with emails, marketing or marketing material, and other data that may interest you. Please get in touch with Reverie to cancel your membership
                                        if you ever decide that you do not want to receive these emails from us. We may also give the combined information to partners without gathering it if we believe they can materially help you or your website
                                        usage. You cannot check out these alerts, so please be aware that we may use your guide to help you with information about how you use the website.
                                    </p>
                                    <p>
                                        The main goal of Use Reverie's data collection is to give you a secure, comfortable, effective, and personalized experience. In addition, we use the data we collect about you in our interactions with other
                                        visitors, members, and partners who buy ads on this website.
                                    </p>
                                    <h5>How Do We Use Your Data?</h5>
                                    <p>
                                        According to your communication preferences, we may tailor, measure, and improve our services and the layout of the Reverie website. We may also use the data you provide to deliver requested customer support,
                                        settle disputes, collect fees, troubleshoot issues, prevent potentially prohibited or illegal activities, enforce the terms and conditions, and compare data for accuracy.
                                    </p>
                                    <h5>How can you edit or remove my data?</h5>
                                    <p>
                                        The "Reverie Log tool" lets you control the information and material you post. Through our retrieve your information service, you can also get data related to your reverie account. We keep records for as long
                                        as it takes to offer you and others the products and services mentioned above. Until your account is closed, we will keep the related information—unless we no longer require it to deliver the services to
                                        maintain your account.
                                    </p>
                                    <h5>Partners, Network Operators, and Providers</h5>
                                    <p>
                                        We share information with buyers, service providers, and other partners who help us run our business globally. Whether by analyzing how customers use our services, determining how effective advertisements and
                                        services are, providing customer service, processing payments, or collecting information and scientific work. Following our Data Policy and the agreements we enter, these collaborators are subject to strong
                                        confidentiality responsibilities.
                                    </p>
                                    <h5>Cookies Policy </h5>
                                    <p>
                                        Cookies are text files that net browsers use to access data. On computers, phones, and other devices, cookies store and retrieve addresses and other information. For similar objectives, we also utilize other
                                        technologies, such as data that we keep on your internet browser or device, device id, and other applications. These tools are referred to as "cookies" in this policy.
                                    </p>
                                    <p>
                                        Cookies assist us in the offering, preserving, and improving the Services by, among other things, allowing us to customize content, target and measure adverts, and ensure a safe customer experience. We utilize
                                        both session and session cookies, the latter of which is deleted when your browser is closed and remains in your browser until it becomes removed or you delete it.
                                    </p>
                                    <h5>Account Security</h5>
                                    <p>
                                        You must use special characters, symbols, and letters not used by anyone else and keep your password private because it is the password to your membership in Reverie. Any actions carried out under your
                                        account's name are your responsibility. If you forget your password, you risk giving others unauthorized access to your account and losing a great deal of control over your data. If you have any cause to
                                        believe your password has been stolen, change it straight away. Checking, updating, and obtaining access to personal data
                                    </p>
                                    <h5>Update Info </h5>
                                    <p>By going into the Reverie website and using the "Edit Portfolio" option on the dashboard, you can read, review, and edit most of your personal information or delete your account.</p>
                                    <p>
                                        To comply with the law, avoid fraud, settle disputes, troubleshoot issues, support any investigations, uphold the Terms, and take other steps made possible by the law, we keep personal data from closed accounts
                                        on file. Your personal information will not be manually modified by Reverie. If your information changes or becomes incorrect, you must update it immediately. Based on your account activity and the Terms of
                                        Service, we will cancel your account and remove your personal information as soon as is practical after receiving your request.
                                    </p>
                                    <h5>How Will You Be Notified If This Policy Changes?</h5>
                                    <p>Before we make any changes to this policy, we'll let you know and give you a chance to read and comment on the new policy before using our services again.</p>
                                    <h5>Children</h5>
                                    <p>
                                        Individuals under 18 are not intended for the use of the Reverie website. Reverie does not knowingly collect Under-15-year-olds' data. Please contact us via the website if Reverie learns that a person under the
                                        age of 15 has given us personal information so that Reverie can quickly remove that information. Parents should use the website to contact our support staff if they learn that their children have given us
                                        Personal Information without their permission.
                                    </p>
                                    <h5>Account Access to Reverie</h5>
                                    <p>In its sole discretion, Reverie retains the right to access your account anytime and make changes to any plans, purchases, postings, or profile information.</p>
                                    <h5>Email Us</h5>
                                    <p>Please contact us through the relevant support link on our website if you have any questions or concerns about our privacy statement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ThemeLayout>
    );
};

export default PrivacyPolicy;
